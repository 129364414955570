import { memo } from 'react'

import PropTypes from 'prop-types'

import { SvgIcon } from '@mui/material'

export const path = `
  M8.49223895,15.2082425
  C8.48901612,15.9652692 8.4311207,16.7888023 8.31980524,17.679326
  C8.26842837,18.0903409 7.89358573,18.3818852 7.48257078,18.3305083
  C7.07155582,18.2791315 6.78001155,17.9042888 6.83138842,17.4932739
  C7.14803852,14.9600731 6.99285463,13.0978665 6.40477644,11.9217101
  C5.97986958,11.0718964 5.49196557,9.96479083 4.93773366,8.5922589
  C4.55806855,8.02963207 3.97499471,7.1013018 3.18851217,5.80726809
  C3.18851217,5.80726809 3.17809238,5.78922049 3.16004477,5.79964028
  C2.7410626,6.90782474 2.56023936,8.09507933 2.56547611,9.27116602
  C2.54742851,9.28158581 2.5578483,9.29963342 2.53980069,9.3100532
  C2.53217288,9.3385206 2.52175309,9.32047299 2.50370549,9.33089278
  C1.76355326,9.13256959 0.766446398,8.69758368 0.286387994,7.69938151
  C0.255128627,7.6452387 0.213449471,7.57304828 0.189817918,7.49043808
  C-0.200002465,6.44013696 0.0133759,5.01751541 0.804277595,3.26146061
  C1.30866195,2.1761596 1.90886827,1.13178962 2.59726874,0.156818096
  C2.71801802,-0.00915042149 2.95822082,-0.0515776849 3.12418934,0.0691715953
  C7.01644764,2.85124088 8.55224436,5.2612439 7.66906078,7.19089503
  C7.5948265,7.35407158 7.51017243,7.49920052 7.40747075,7.65474925
  C7.16347223,8.00928318 6.85144667,8.30349403 6.53609264,8.54003404
  C6.98938623,9.64017611 7.39306192,10.5441791 7.74641722,11.2508897
  C7.96415101,11.6863573 8.13347808,12.1770754 8.25536949,12.7234194
  C8.47859282,12.3753482 8.73944602,12.0512715 9.03149489,11.7534047
  L11.7462746,9.18221717
  C11.7462746,9.18221717 11.7554101,9.16348668 11.7366796,9.15435121
  C10.5836612,9.42668814 9.49826743,9.94068322 8.52617572,10.602684
  C8.50744524,10.5935485 8.49830977,10.612279 8.47957928,10.6031436
  C8.45171332,10.6127386 8.46084879,10.5940081 8.4421183,10.5848726
  C8.19264781,9.86035771 7.99569238,8.79047762 8.55479424,7.83430359
  C8.58220065,7.77811213 8.61874253,7.70319017 8.67401489,7.63740369
  C9.32676919,6.72690701 10.6254956,6.10828582 12.5235976,5.78199966
  C13.7054011,5.59326061 14.9068541,5.50684992 16.1000908,5.53236262
  C16.3052071,5.53965989 16.4747006,5.71507201 16.4674033,5.92018829
  C16.3374866,10.7027301 15.1983101,13.3236198 13.1046869,13.6704747
  C12.9278961,13.7001788 12.7602408,13.7111525 12.573855,13.7129907
  C11.6044651,13.7039108 10.6958066,13.2375422 10.2362203,12.9438286
  C9.81185275,13.386063 9.26648389,13.7013887 8.55479424,15.0862999
  C8.53307421,15.128566 8.51223359,15.1691836 8.49223895,15.2082425
  Z
`

const BatchIconComponent = ({
  color = 'appSecondary.main',
  faded = false,
  sx: sxProp = undefined,
  ...rest
}) => (
  <SvgIcon
    {...rest}
    color={color}
    sx={faded ? { ...sxProp, opacity: theme => theme.palette.states.active } : sxProp}
  >
    <path data-iconid="aroya-icons-batch" d={path} transform="translate(4, 3)" />
  </SvgIcon>
)
BatchIconComponent.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  faded: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  title: PropTypes.string,
}
BatchIconComponent.displayName = 'BatchIcon'

const Memoized = memo(BatchIconComponent)
export default Memoized
