import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'

import { inflect } from 'inflection'

import {
  IRRIGATION_SCHEDULE_STATUSES,
  TIME_DURATION_TYPE_VALUE,
  TIME_UNIT_OPTIONS,
} from '~/src/Irrigation/constants'
import createLogger from '~/src/Lib/Logging'
import {
  convertDateTimeToSeconds,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formatList,
  formattedDate,
  getListLabel,
} from '~/src/Lib/Utils'

import { SPACES } from '../Lib/Constants'

const logger = createLogger('Irrigation/utils')

export const FULL_DAY_IN_SECONDS = 24 * 60 * 60

const ERROR_PREFIX_LOOKUP = {
  ALREADY_QUEUED: i18n`already queued.`,
  PENDING_TIMEOUT: i18n`Manual event has been pending for longer than expected.`
}

export const collapseLargeScheduleName = (nameRaw, selectedHarvest, selectedRoom, isTemplate) => {
  const name = nameRaw ?? ''
  let title = i18n`Are you sure you want to remove "${name}" irrigation ${isTemplate ? i18n`template` : i18n`schedule`}`
  if (selectedHarvest || selectedRoom) {
    title += i18n` from ${selectedHarvest?.name ? selectedHarvest?.name : selectedRoom?.name}?`
  } else {
    title += '?'
  }

  if (name.length < 50) {
    return {
      title,
      hasLargeName: false
    }
  }
  if (name.split(' ').length - 1 >= Math.floor((name.length * 5) / 128) || name.split('-').length - 1 >= Math.floor((name.length * 5) / 128)) {
    return {
      title,
      hasLargeName: false
    }
  }

  return {
    hasLargeName: true,
    irrigationType: isTemplate ? i18n`irrigation template` : i18n`irrigation schedule`,
    from: selectedHarvest || selectedRoom ? i18n` from ${selectedHarvest?.name ?? selectedRoom?.name}?` : '?'
  }
}

const convertStartTimeToSeconds = (programStartDT, firstStartDT) => (
  convertDateTimeToSeconds(firstStartDT) + programStartDT.diff(firstStartDT).as('seconds')
)
// we don't support anything but time duration so far - returning a convertion error to adjust some UI parts
export const validateProgramDurations = programs => {
  const hasError = programs.some(program => {
    const { durationTypeSelectValue } = program
    const hasDurationNotAsTimeType = durationTypeSelectValue !== TIME_DURATION_TYPE_VALUE
    return hasDurationNotAsTimeType
  })

  return hasError
}

const validProgramTest = program => program?.startTime && typeof program?.durationValue === 'number'

// NOTE: works with irrigations that are transformed to an appropriate form state shape
export const getIrrigationOccurrences = memoizeOne(programs => {
  // firstly, we need to filter out invalid programs (that do not have either start time or duration defined)
  const firstValidProgram = programs.find(validProgramTest)
  if (!firstValidProgram) return EMPTY_ARRAY
  const utcFirstStart = firstValidProgram.startTime.setZone('utc', { keepLocalTime: true })
  // secondly, we prepare an array of occurrences per each program based on the input values
  const occurrencesPerEachProgram = programs.map(program => {
    if (!validProgramTest(program)) return EMPTY_ARRAY
    const {
      name,
      startTime,
      durationValue,
      durationInputSelectValue,
      repeatAfter,
      repeatAfterSelectValue,
      occurrences,
    } = program

    const utcStartTime = startTime.setZone('utc', { keepLocalTime: true })
    const startTimeInSeconds = convertStartTimeToSeconds(utcStartTime, utcFirstStart)

    // TODO: adjust durationValue formatting for other types. Will not work properly when defining ml or %
    const valueConverter = TIME_UNIT_OPTIONS.find(measurement => measurement.value === durationInputSelectValue)
    const durationValueInSeconds = valueConverter?.toSeconds(durationValue) || durationValue || 0

    if (!repeatAfter || !occurrences) {
      // if either of above is not defined - we have only one occurrence
      return [{
        name,
        startTime: utcStartTime,
        startTimeInSeconds,
        durationValue,
        durationInputSelectValue,
        durationValueInSeconds,
      }]
    }
    const timeUnit = TIME_UNIT_OPTIONS.find(measurement => measurement.value === repeatAfterSelectValue)
    const repeatAfterInSeconds = timeUnit ? timeUnit.toSeconds(typeof repeatAfter === 'number' ? repeatAfter : 0) : 0
    const occurrencesArr = []
    let timeOffsetInSeconds = 0

    let i = 0
    while (i < occurrences) {
      occurrencesArr.push({
        name,
        startTime: utcStartTime.plus({ seconds: timeOffsetInSeconds }),
        startTimeInSeconds: startTimeInSeconds + timeOffsetInSeconds,
        durationValue,
        durationInputSelectValue,
        durationValueInSeconds,
      })
      timeOffsetInSeconds = timeOffsetInSeconds + durationValueInSeconds + repeatAfterInSeconds
      i += 1
    }

    return occurrencesArr
  })

  return occurrencesPerEachProgram
})

export const getProgramTiming = memoizeOne(programs => {
  const ocurrencesPerProgram = getIrrigationOccurrences(programs)
  return ocurrencesPerProgram.map(programOccurrences => {
    if (!programOccurrences.length) return EMPTY_OBJECT
    const { 0: firstOccurence, [programOccurrences.length - 1]: lastOccurrence } = programOccurrences
    return {
      start: firstOccurence.startTimeInSeconds,
      end: lastOccurrence.startTimeInSeconds + lastOccurrence.durationValueInSeconds,
    }
  })
})

// TODO: adjust necessary utils after starting to support % and ml instead of time as duration value
export const getTotalDurationInSeconds = memoizeOne(p => {
  const programs = p?.length ? p : [p]
  const occurrencesPerProgram = getIrrigationOccurrences(programs)

  const hasDurationOnlyAsTime = programs.every(program => program?.durationTypeSelectValue === TIME_DURATION_TYPE_VALUE)
  if (hasDurationOnlyAsTime) {
    const {
      0: firstProgram = EMPTY_ARRAY,
      [occurrencesPerProgram.length - 1]: lastProgram = EMPTY_ARRAY
    } = occurrencesPerProgram
    const { 0: firstOccurence = EMPTY_OBJECT } = firstProgram
    const { startTimeInSeconds: startForDay = 0 } = firstOccurence
    const { [lastProgram.length - 1]: lastOccurrence = EMPTY_OBJECT } = lastProgram
    const {
      startTimeInSeconds: lastStartForDay = 0,
      durationValueInSeconds: lastDurationForDay = 0
    } = lastOccurrence
    const endForDay = lastStartForDay + lastDurationForDay

    return endForDay - startForDay
  }
  return 0
})

export const getDurationInSecondsPerProgram = memoizeOne(p => {
  const programs = p?.length ? p : [p]
  return programs.map(program => getTotalDurationInSeconds(program))
})

export const formatDuration = durationInSeconds => {
  if (durationInSeconds < 0) {
    return i18n`Invalid duration`
  }

  if (durationInSeconds === 0) {
    return `${durationInSeconds}s`
  }

  const durationHours = Math.floor(durationInSeconds / 3600)
  const durationMinutes = Math.floor(durationInSeconds / 60) % 60
  const durationSeconds = durationInSeconds % 60
  return ([
    durationHours && `${durationHours}h`,
    durationMinutes && `${durationMinutes}m`,
    durationSeconds && `${durationSeconds}s`
  ]).filter(Boolean).join(SPACES.THSP)
}

// NOTE: if withinTotalDuration is false - the dots are placed within 24 hours line, otherwise - within the total duration of the schedule
export const getIrrigationPoints = (programs, withinTotalDuration = false) => {
  const occurrencesPerEachProgram = getIrrigationOccurrences(programs)
  const totalDuration = getTotalDurationInSeconds(programs)

  // in the end, we calculate the position of each occurrence relatively to the line width in seconds
  const irrigationPoints = occurrencesPerEachProgram.map((programOccurrences, _, allProgramsOccurrences) => programOccurrences.map(occurrence => {
    const { name, startTime, startTimeInSeconds: startTimeIS, durationValueInSeconds } = occurrence

    // if it's within the duration - we place the dots proportionally within the schedule duration rather that 24 hours line
    if (withinTotalDuration) {
      return {
        name,
        title: `${formattedDate(startTime, 'SHORT_TIME')} - ${formatDuration(durationValueInSeconds)}`,
        // we need to calculate left position relative to the very first occurrence of the very first program in a schedule
        left: ((startTimeIS - allProgramsOccurrences[0][0].startTimeInSeconds) / totalDuration) * 100,
        width: (durationValueInSeconds / totalDuration) * 100,
        startTime,
        startTimeInSeconds: startTimeIS,
        durationValueInSeconds,
      }
    }

    const startTimeInSeconds = startTimeIS % FULL_DAY_IN_SECONDS

    return {
      name,
      title: `${formattedDate(startTime, 'SHORT_TIME')} - ${formatDuration(durationValueInSeconds)}`,
      left: (startTimeInSeconds / FULL_DAY_IN_SECONDS) * 100,
      width: (durationValueInSeconds / FULL_DAY_IN_SECONDS) * 100,
      startTime,
      startTimeInSeconds,
      durationValueInSeconds,
    }
  }))

  return irrigationPoints
}

export const validateFormWithEmergencyShot = memoizeOne(({
  programs,
  sensorBasedIrrigationCooldownMinutes,
  sensorBasedIrrigationMinutes,
  sensorBasedLightScheduleMode,
  sensorBasedMaxEcpw,
  sensorBasedMinVwc,
  sensorBasedZoneTriggerMode,
}) => {
  const errors = {}
  // validating the overall duration of the irrigation schedule
  const totalDurationInSeconds = getTotalDurationInSeconds(programs)
  logger.debug('[validateFormWithEmergencyShot] totalDurationInSeconds:', totalDurationInSeconds, {
    hours: Math.floor(totalDurationInSeconds / 3600),
    minutes: Math.floor((totalDurationInSeconds % 3600) / 60),
    seconds: totalDurationInSeconds % 3600 % 60
  })
  if (totalDurationInSeconds > FULL_DAY_IN_SECONDS) {
    errors.general = i18n`The schedule should not last more than 24 hours!`
  }
  if (sensorBasedLightScheduleMode !== 'DISABLE') {
    if (!sensorBasedZoneTriggerMode) errors.sensorBasedZoneTriggerMode = i18n`Please select a trigger mode`
    if (!sensorBasedMinVwc && !sensorBasedMaxEcpw) {
      errors.sensorBasedMinVwc = i18n`Please define a VWC or an ECpw`
      errors.sensorBasedMaxEcpw = i18n`Please define a VWC or an ECpw`
    }
    if (!sensorBasedIrrigationMinutes) errors.sensorBasedIrrigationMinutes = i18n`Please select a duration for the event`
    if (!sensorBasedIrrigationCooldownMinutes) errors.sensorBasedIrrigationCooldownMinutes = i18n`Please select a duration between events`
    if (!programs.length) return errors
  }

  const hasDurationOnlyAsTime = programs.every(program => program.durationTypeSelectValue === TIME_DURATION_TYPE_VALUE)
  // we decided to cut off the validation so far if the user tries to define duration as something else than time (% or ml)
  if (hasDurationOnlyAsTime) {
    const programsTiming = getProgramTiming(programs)
    programs.forEach((program, index) => {
      // validating each program to have a valid duration value
      if (!program.durationValue) {
        if (!errors.programs) errors.programs = Array(programs.length)
        errors.programs[index] = { durationValue: i18n`Please define the duration` }
        return
      }
      // validating each program that is repeating has a valid repeatAfter value
      if (program.occurrences > 1 && !program.repeatAfter) {
        if (!errors.programs) errors.programs = Array(programs.length)
        errors.programs[index] = { repeatAfter: i18n`Please define a repeat value` }
        return
      }
      // validate programs not overlapping
      const { [index]: programTiming } = programsTiming

      if (!programTiming) return

      const { start, end } = programTiming
      const overlapping = programsTiming.slice(0, index).filter(other => (
        (other.start < start && other.end > start)
        || (end > other.start && end < other.end)
      ))
      if (overlapping.length) {
        if (!errors.programs) errors.programs = Array(programs.length)
        errors.programs[index] = {
          startTime: i18n`Overlaps with ${formatList(overlapping.map(timing => `P${programsTiming.indexOf(timing) + 1}`), '&')
            }.`
        }
      }
    })
  }

  if (programs.length > 10) {
    errors.general = i18n`The schedule can't have more than 10 programs`
  }

  if (!programs.length) {
    errors.general = i18n`The schedule must have at least 1 program defined or an emergency event set`
  }

  return errors
})

export const validateForm = memoizeOne(async ({ programs }) => {
  const errors = {}

  // validating the overall duration of the irrigation schedule
  const totalDurationInSeconds = getTotalDurationInSeconds(programs)
  logger.debug('[validateForm] totalDurationInSeconds:', totalDurationInSeconds, {
    hours: Math.floor(totalDurationInSeconds / 3600),
    minutes: Math.floor((totalDurationInSeconds % 3600) / 60),
    seconds: totalDurationInSeconds % 3600 % 60
  })
  if (totalDurationInSeconds > FULL_DAY_IN_SECONDS) {
    errors.general = i18n`The schedule should not last more than 24 hours!`
  }

  const hasDurationOnlyAsTime = programs.every(program => program.durationTypeSelectValue === TIME_DURATION_TYPE_VALUE)
  // we decided to cut off the validation so far if the user tries to define duration as something else than time (% or ml)
  if (hasDurationOnlyAsTime) {
    const programsTiming = getProgramTiming(programs)
    programs.forEach((program, index) => {
      // validating each program to have a valid duration value
      if (!program.durationValue) {
        if (!errors.programs) errors.programs = Array(programs.length)
        errors.programs[index] = { durationValue: i18n`Please define the duration` }
        return
      }
      // validating each program that is repeating has a valid repeatAfter value
      if (program.occurrences > 1 && !program.repeatAfter) {
        if (!errors.programs) errors.programs = Array(programs.length)
        errors.programs[index] = { repeatAfter: i18n`Please define a repeat value` }
        return
      }
      // validate programs not overlapping
      const { [index]: programTiming } = programsTiming

      if (!programTiming) return

      const { start, end } = programTiming
      const overlapping = programsTiming.slice(0, index).filter(other => (
        (other.start < start && other.end > start)
        || (end > other.start && end < other.end)
      ))
      if (overlapping.length) {
        if (!errors.programs) errors.programs = Array(programs.length)
        errors.programs[index] = {
          startTime: i18n`Overlaps with ${formatList(overlapping.map(timing => `P${programsTiming.indexOf(timing) + 1}`), '&')
            }.`
        }
      }
    })
  }

  if (programs.length > 10) {
    errors.general = i18n`The schedule can't have more than 10 programs`
  }

  if (!programs.length) {
    errors.general = i18n`The schedule must have at least 1 program defined`
  }

  return errors
})

export const validateSetUpStep = (values, errors = {}) => {
  if (values.roomLevel) {
    return Boolean(values?.zones?.length && !values.hasControllerErrors && !errors.name)
  }

  // otherwise, we are just creating a template
  return Boolean((values.runOnce || values.relatedPhaseType) && (values.name && !errors.name))
}

// TODO: discuss better solution to get expected unit objects
export const getDurationUnits = (units = {}) => Object.values(units).filter(
  unitObj => unitObj.category.name === 'Time' || (unitObj.category.name === 'Volume' && unitObj.name === 'Milliliter') || unitObj.category.name === 'Water Content'
)

export const checkIfRoomHasIrrigationSettings = room => {
  // TODO: this is a temporary checking logic until receiving more information about which room is considered with or without irrigation settings
  const {
    substrateName,
    blockVolume,
    blockVolumeUnit,
    slabVolume,
    slabVolumeUnit,
    blocksPerSlab,
    emittersPerPlant,
    emitterFlowRate,
    emitterFlowRateVolumeUnit,
    emitterFlowRateTimeUnit,
  } = room

  return Boolean(
    substrateName
    || blockVolume
    || blockVolumeUnit
    || slabVolume
    || slabVolumeUnit
    || blocksPerSlab
    || emittersPerPlant
    || emitterFlowRate
    || emitterFlowRateVolumeUnit
    || emitterFlowRateTimeUnit
  )
}

const getHarvestNextPhase = (harvest, phases) => {
  const { currentPhase, phases: harvestPhases } = harvest
  const nextHarvestPhase = harvestPhases.find(phase => phase.startDate === currentPhase.endDate) || {}
  const nextPhase = phases[nextHarvestPhase.id] || {}

  return nextPhase
}

export const getHarvestNextPhaseIrrigationId = ({ harvest, phases }) => {
  const nextPhase = getHarvestNextPhase(harvest, phases)
  const {
    irrigationSchedule: irrigationScheduleId,
  } = nextPhase

  return irrigationScheduleId
}

export const getScheduleStatusMessage = status => {
  if (status === IRRIGATION_SCHEDULE_STATUSES.ACTIVE) {
    return i18n`Currently active`
  }

  if (status === IRRIGATION_SCHEDULE_STATUSES.PENDING) {
    return i18n`Schedule pending…`
  }

  return i18n`Currently ${status?.toLowerCase()}`
}

export const getSchedulePortIds = (schedule, zones) => {
  if (!Array.isArray(schedule?.zones)) return EMPTY_ARRAY
  return new Set(schedule.zones.map(zoneId => zones[zoneId]?.portId).filter(Boolean))
}

export const getManualIrrigationErrorMessages = (
  errors,
  irrigationControllerPorts,
  zones
) => {
  if (!errors || errors?.length === 0) return EMPTY_ARRAY
  const sortedErrorPortIds = errors.reduce((acc, { errorType, portId }) => ({
    ...acc,
    [errorType]: [...acc[errorType] || [], portId],
  }), {})

  return Object.entries(sortedErrorPortIds).reduce((errorMessages, [errorType, portIds]) => {
    if (errorType === 'ALREADY_QUEUED') {
      const portZoneIds = portIds.flatMap(id => irrigationControllerPorts[id]?.zones)
      const zonesListLabel = getListLabel(portZoneIds.map(id => zones[id]))
      errorMessages.push(i18n`Error: ${zonesListLabel} ${inflect(i18n`are`, portZoneIds.length, i18n`is`)} ${ERROR_PREFIX_LOOKUP[errorType]}`)
      return errorMessages
    }
    errorMessages.push(i18n`Error: ${ERROR_PREFIX_LOOKUP[errorType]}`)
    return errorMessages
  }, [])
}
