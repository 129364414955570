import ms from 'milliseconds'
import queryString from 'query-string'

import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, getDateTime } from '~/src/Lib/Utils'
import { ControlSensorBasedEvent as schema } from '~/src/Store/Schemas'

const logger = createLogger('Irrigation/controlSensorBasedEvents#bundle')

const initialState = {
  ...defaultInitialState,
}

export default createListBundle({
  entityName: 'controlSensorBasedEvents',
  name: 'controlSensorBasedEventsList',
  initialState,
  actions: EMPTY_ARRAY,
  schema,
  flags: {
    keys: ['EMERGENCY_SHOT']
  },
  fetchHandler: ({ apiFetch }) => apiFetch(`/controlSensorBasedEvent/?${queryString.stringify({
    start: getDateTime('now').minus({ hours: 24 }),
  })}`),
  staleAfter: ms.minutes(300),
})
