import { pick, prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import { camelize, inflect } from 'inflection'
import { normalize } from 'normalizr'

import createEntityBundle, {
  doEntitiesReceived,
  getAsyncActionIdentifiers,
  getDispatchPayloadFactory,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT, parseApiErrors } from '~/src/Lib/Utils'
import { ACTIONS } from '~/src/Routes/Register/bundle'
import { Facility as schema, FacilityStatus } from '~/src/Store/Schemas'

import { LOCATIONS_TERMS } from '../constants'
import { prepareData } from './shape'
import { buildHierarchy } from './utils'

const name = 'facilities'
const getRoot = prop(name)
const logger = createLogger(`${name}/bundle`)
const getDispatchPayload = getDispatchPayloadFactory(name, schema)

const getLocationTermType = (locationEntity, availableFeatures) => {
  if (availableFeatures.has('FLOW_METER')) {
    return 'flowMeter'
  }
  return locationEntity?.outdoor ? 'outdoor' : 'default'
}

const REMOVE_EASY_SETUP_MODAL = 'REMOVE_EASY_SETUP_MODAL'
const REMOVE_EASY_SETUP_MODAL_FAILED = 'REMOVE_EASY_SETUP_MODAL_FAILED'

const { selectCurrentFacilityId: _, ...initialBundle } = createEntityBundle({
  autoFetch: false,
  name,
  apiConfig: { prepareData, schema },
})

const {
  doFacilitySave: { types: saveTypes },
  reducer: entityReducer,
} = initialBundle

export const initialState = Object.freeze({
  ...entityReducer.defaultState,
  hierarchy: { loading: false },
  status: { loading: false, params: EMPTY_OBJECT, items: EMPTY_OBJECT },
  activeRoom: null,
})

const fetchHierarchy = getAsyncActionIdentifiers('fetch', 'hierarchy')
const hierarchyReducer = (state, action) => {
  switch (action.type) {
    case fetchHierarchy.types.start:
      return {
        ...state,
        hierarchy: {
          ...state.hierarchy,
          loading: true,
        },
      }
    case fetchHierarchy.types.succeed:
      return {
        ...state,
        hierarchy: {
          ...state.hierarchy,
          loading: false,
          facilities: action.payload,
        },
      }
    case fetchHierarchy.types.fail:
      return {
        ...state,
        hierarchy: {
          ...state.hierarchy,
          loading: false,
          error: action.error,
        },
      }
    default:
      return state
  }
}

const fetchStatus = getAsyncActionIdentifiers('status_fetch', 'facility')
const statusReducer = (state, action) => {
  switch (action.type) {
    case fetchStatus.types.start:
      return {
        ...state,
        status: {
          ...state.status,
          params: action.payload,
          loading: true,
        },
      }
    case fetchStatus.types.succeed:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          items: action.payload,
        },
      }
    case fetchStatus.types.fail:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: action.error,
        },
      }
    default:
      return state
  }
}

// TODO: add fetchHierarchy handlinger reducer
export default {
  ...initialBundle,
  reducer: (state = initialState, action = EMPTY_OBJECT) => {
    if (action.type && action.type.startsWith(fetchHierarchy.types.prefix)) {
      return hierarchyReducer(state, action)
    }

    if (action.type && action.type.startsWith(fetchStatus.types.prefix)) {
      return statusReducer(state, action)
    }

    return entityReducer(state, action)
  },
  doFacilitySave: payload => async ({ dispatch, store, apiFetch }) => {
    const cleaned = prepareData(payload)
    const dispatchPayload = getDispatchPayload(cleaned)

    dispatch({ type: saveTypes.start, payload: dispatchPayload })

    try {
      const saveResult = await apiFetch('/facility/', cleaned, {
        method: 'PUT',
        allowedCodes: [400],
      })
      if ('id' in saveResult) {
        dispatch({ type: saveTypes.succeed, payload: dispatchPayload })
        const { entities } = normalize(saveResult, schema)
        dispatch(doEntitiesReceived(entities, { replace: false }))
        store.doAddSnackbarMessage('Successfully saved facility details')
      } else {
        const error = parseApiErrors(saveResult)
        dispatch({ type: saveTypes.fail, payload: dispatchPayload, error })
        store.doAddSnackbarMessage(error)
      }
    } catch (error) {
      dispatch({ type: saveTypes.fail, payload: dispatchPayload, error })
    }
  },
  doHierarchyFetch: () => async ({ dispatch, apiFetch }) => {
    dispatch({ type: fetchHierarchy.types.start })
    let result = false
    try {
      result = await apiFetch('/hierarchy/')
      const { entities } = normalize(Object.values(result), [schema])
      dispatch({
        type: fetchHierarchy.types.succeed,
        payload: entities,
      })
    } catch (error) {
      result = error
      dispatch({
        type: fetchHierarchy.types.fail,
        error,
      })
    }
    return result
  },
  selectFacilityJournal: prop('journal'),
  doFacilityStatusFetch: payload => async ({ apiFetch, dispatch }) => {
    dispatch({ type: fetchStatus.types.start, payload })
    let result = false
    try {
      result = await apiFetch.fetch('/facility/status/', payload)
      dispatch({ type: fetchStatus.types.succeed, payload: result })
      const { entities } = normalize(result, FacilityStatus)
      dispatch(doEntitiesReceived(entities, { replace: true }))
    } catch (error) {
      result = error
      dispatch({ type: fetchStatus.types.fail, error })
    }
    return result
  },
  selectFacilityStatus: createSelector(
    getRoot,
    prop('status')
  ),
  selectFacilityStatusByUUID: createSelector(
    'selectFacilityStatus',
    facilityStatus => {
      const { items } = facilityStatus
      const areas = pick(['rooms', 'zones'], typeof items === 'object' ? items : EMPTY_OBJECT)

      return Object.entries(areas).reduce(
        (acc, [type, areaEntries]) => ({
          ...acc,
          ...Object.entries(areaEntries).reduce(
            (acc2, [pk, { notifications, status }]) => ({
              ...acc2,
              [`${type}_${pk}`]: {
                status,
                notifications:
                  notifications
                  && notifications?.map(notification => ({
                    ...notification,
                    uuid: `notifications_${notification.id}`,
                  })),
              },
            }),
            EMPTY_OBJECT
          ),
        }),
        EMPTY_OBJECT
      )
    }
  ),
  selectFacilityStatusHarvests: createSelector(
    'selectFacilityStatus',
    facilityStatus => {
      const { items } = facilityStatus
      const areas = pick(['harvestRooms', 'harvestZones'], items || EMPTY_OBJECT)

      return Object.entries(areas).reduce((acc, [type, areaEntries]) => {
        const areaType = type.replace('harvest', '').toLowerCase()

        return Object.entries(areaEntries).reduce(
          (acc2, [pk, associations]) => ({
            ...acc2,
            [`harvests_${pk}`]: [
              ...(acc[`harvests_${pk}`] ?? []),
              ...associations.map(id => `${areaType}_${id}`),
            ],
            ...associations.reduce((acc3, id2) => {
              if (acc2[`${areaType}_${id2}`]) {
                acc2[`${areaType}_${id2}`].push(`harvests_${pk}`)
              } else {
                acc3[`${areaType}_${id2}`] = [`harvests_${pk}`]
              }
              return acc3
            }, {}),
          }),
          acc
        )
      }, EMPTY_OBJECT)
    }
  ),
  selectFacilityHierarchy: createSelector(
    state => state.facilities.hierarchy,
    'selectCurrentFacility',
    'selectFacilities',
    'selectRooms',
    'selectZones',
    'selectDevices',
    (hierarchy, currentFacility, ...rest) => {
      const { facilities: hierarchyFacilities } = hierarchy

      if (hierarchyFacilities && Object.keys(hierarchyFacilities).length) {
        return hierarchyFacilities
      }
      const fakeHierarchy = buildHierarchy(currentFacility, ...rest)
      return fakeHierarchy
    }
  ),
  selectFacilityUnits: createSelector(
    'selectCurrentFacility',
    'selectUnits',
    (currentFacility, units) => {
      if (!currentFacility) return EMPTY_OBJECT
      const { unitPreferences } = currentFacility
      const prefsByCategory = (unitPreferences ?? EMPTY_ARRAY).reduce((prefs, { category, unit }) => ({
        ...prefs,
        [category]: unit,
      }), EMPTY_OBJECT)

      return pick(Object.values(units ?? EMPTY_OBJECT).filter(unit => {
        const { [unit.category.id]: pref } = prefsByCategory
        if (pref === unit.id || (!prefsByCategory[unit.category.id] && unit.default)) {
          return true
        }
        return false
      }).map(prop('id')), units)
    }
  ),
  selectFacilityUnitsByCategory: createSelector(
    'selectFacilityUnits',
    facilityUnits => Object.values(facilityUnits).reduce((byCategory, unit) => ({
      ...byCategory,
      [camelize(unit.category.name.toLowerCase().split(' ').join('_'), true)]: unit,
    }), EMPTY_OBJECT)
  ),
  selectFacilityLocationsTerm: createSelector(
    'selectConfig',
    'selectAvailableFeatures',
    (config = EMPTY_OBJECT, availableFeatures = new Set()) => (
      locations = EMPTY_OBJECT,
      type = 'room',
      appTerms = (LOCATIONS_TERMS[config.APP] ?? LOCATIONS_TERMS.AROYA)
    ) => {
      let term
      if (!Array.isArray(locations)) {
        const termType = getLocationTermType(locations, availableFeatures)
        const { [termType]: terms = appTerms.default } = appTerms

        if (type in terms) {
          term = terms[type]
        } else {
          logger.warn('[selectFacilityLocationsTerm] unable to look up locations term for:', {
            locations,
            type,
            appTerms
          })
          term = 'locations'
        }
        return inflect(term, 1)
      }
      const { 0: first } = locations
      const termType = getLocationTermType(first, availableFeatures)
      const { [termType]: firstTerms = appTerms.default } = appTerms

      if (!locations.every(l => appTerms[l.outdoor ? 'outdoor' : 'default'] === firstTerms)) {
        return inflect('locations', locations.length)
      }

      if (type in firstTerms) {
        term = firstTerms[type]
      } else {
        console.warn('unable to look up locations term for:', {
          locations,
          type,
          appTerms
        })
        term = 'locations'
      }

      return inflect(term, locations.length ?? 1)
    },
  ),
  doRemoveEasySetupModal: () => async ({ dispatch, apiFetch }) => {
    let result
    try {
      result = await apiFetch('/facility/seen_first_flow/', null, { method: 'POST' })
      const { entities } = normalize(result, schema)
      dispatch(doEntitiesReceived(entities, { replace: false }))
    } catch (error) {
      dispatch({ type: REMOVE_EASY_SETUP_MODAL_FAILED, error })
    }
    dispatch({ type: REMOVE_EASY_SETUP_MODAL, payload: result })
    dispatch({ type: ACTIONS.CLEAR_ACCOUNT_CREATION })
  },
}
