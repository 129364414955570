import { createElement, Fragment } from 'react'

import { identity } from 'ramda'

import {
  camelize,
  humanize,
  singularize,
  underscore,
} from 'inflection'
import { normalize } from 'normalizr'

import safeStorage from '~/src/Lib/safeStorage'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  noop,
  parseApiErrors,
  uniqueId,
} from '~/src/Lib/Utils'
import T from '~/src/UI/Shared/Typography'

import createLogger from '../Logging'
import {
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  getActionIdentifiers,
  getAsyncActionIdentifiers,
} from './constants'

const localStorage = safeStorage('local')

const logger = createLogger('createEntityBundle/actions')

export const doEntitiesReceived = (payload, meta) => ({
  type: ENTITIES_RECEIVED,
  payload,
  meta,
})

export const doEntitiesRemoved = (payload, meta) => ({
  type: ENTITIES_REMOVED,
  payload,
  meta,
})

const idOrValue = (o, schema = EMPTY_OBJECT) => {
  if (schema === EMPTY_OBJECT) {
    logger.warn('idOrValue called without schema:', o)
  }
  const { idAttrbute = 'id' } = schema
  if (o && typeof o === 'object') {
    return o[idAttrbute] ?? o.id ?? o.cid
  }
  return o
}
export const getDispatchPayloadFactory = (name, schema) => payload => {
  if (!schema) {
    throw new TypeError('getDispatchPayloadFactory(name, schema) called without schema, but schema is required.')
  }
  const dispatchPayload = idOrValue(payload, schema) || uniqueId(`${name}_`)
  logger.debug('[getDispatchPayloadFactory]', { dispatchPayload, name, payload })
  return dispatchPayload
}
export const removeEntitiesPayloadFactory = (name, schema) => payload => ({
  [name]: Array.isArray(payload)
    ? payload.map(o => idOrValue(o, schema))
    : Array.of(idOrValue(payload, schema)),
})

const createSnackbarActionFactory = config => {
  const { action, formatError, singularName, snackbar } = config
  if (snackbar === false) return noop
  if (typeof snackbar === 'function') return snackbar
  if (snackbar.indexOf(`do${camelize(singularName)}`) === 0) {
    return ({ dispatch, status, payload, response }) => {
      dispatch({
        actionCreator: snackbar,
        args: [{ ...config, status, payload, response }],
      })
    }
  }
  return ({ dispatch, status, payload, response, error }) => {
    const snackbarString = payload?.[snackbar] ?? response?.[snackbar]
    const identifier = snackbarString ? ` "${snackbarString}"` : ''
    const statusSuffixed = `${status}${status.match(/e$/) ? 'd' : 'ed'}`

    const parts = [
      `${humanize(action)} ${statusSuffixed} for`,
      `${humanize(underscore(singularName), true)}${identifier}`,
      formatError(parseApiErrors(error)),
    ].filter(Boolean)
    const totalLength = parts.reduce((len, part) => (len.length ?? len) + part.length)
    const [actionMsg, identifiers, errorMsg] = parts
    const snackMessage = totalLength < 60
      ? [[actionMsg, identifiers].join(' '), errorMsg].join(errorMsg ? ': ' : ' ')
      : createElement(Fragment, null, [
        createElement(T.Body, null, `${actionMsg} ${identifiers}${errorMsg ? ':' : ''}`),
        errorMsg ? createElement(T.Bold, { color: 'error' }, errorMsg) : null,
      ])
    if (errorMsg) {
      console.warn(parts.join(' '))
    }
    dispatch({
      actionCreator: 'doAddSnackbarMessage',
      args: [snackMessage],
    })
  }
}

const getMeta = ({ status, defaultMeta, paramsMeta, ...rest }) => ({
  ...(defaultMeta?.[status] ?? defaultMeta),
  ...(paramsMeta?.[status] ?? paramsMeta),
  ...rest,
})
/**
 * @typedef {Function} AsyncActionHandler
 * @param {Object} params
 * @param {Object} params.payload
 * @param {Function} params.apiFetch
 * @param {function(): Object} params.getState
 * @param {Object} params.store
 */

/**
 * @typedef {Object} AsyncActionConfig
 * @property {string} action
 * @property {string} name
 * @property {AsyncActionHandler} handler
 * @property {import('normalizr').Entity} schema
 * @property {boolean} remove
 * @property {string|function|boolean} snackbar
 * @property {string[]} [cascade=[]]
 * @property {boolean} [conditional=false]
 * @property {Object} [defaultMeta={}]
 * @property {string} [idAttribute='id']
 * @property {string} [lastModifiedAttribute='modifiedOn']
 * @property {string} [singularName]
 * @property {function} [prepareData]
 * @property {boolean} [returnRaw=false]
 * @property {function} [formatError]
 * @property {Record<string, string>} [parents]
 */

/**
 * @typedef {Function} AsyncAction
 * @property {string} actionName
 * @property {{ prefix: string, }} types
 */

/**
 * Create an async action creator and its action types
 * @param {AsyncActionConfig} config
 * @returns {AsyncAction}
 */
export const asyncActionFactory = config => {
  const {
    action,
    cascade = EMPTY_ARRAY,
    conditional: configConditional = false,
    defaultMeta = {},
    handler,
    idAttribute = 'id',
    lastModifiedAttribute = 'modifiedOn',
    name,
    parents,
    prepareData = identity,
    remove = false,
    returnRaw: configReturnRaw = false,
    schema,
    singularName = singularize(name),
    snackbar = 'name',
    formatError = identity,
  } = config

  const identifiers = getAsyncActionIdentifiers(action, singularName)
  const { types } = identifiers
  const getRemovePayload = removeEntitiesPayloadFactory(name, schema)
  const cascadeRemovers = cascade.reduce((removers, [child]) => {
    removers[child] = removeEntitiesPayloadFactory(child, schema)
    return removers
  }, {})
  const getDispatchPayload = getDispatchPayloadFactory(name, schema)
  const createSnackbarAction = createSnackbarActionFactory({
    action,
    formatError,
    name,
    singularName,
    snackbar,
  })
  const creator = (rawPayload, paramsMeta) => async ({
    apiFetch: apiFetchOriginal,
    dispatch,
    store,
    ...handlerArgs
  }) => {
    const payload = prepareData(rawPayload)
    const dispatchPayload = getDispatchPayload(payload)
    const meta = getMeta({ defaultMeta, status: 'entities', paramsMeta })
    const sharedSnackbarArgs = {
      ...handlerArgs,
      action,
      dispatch,
      meta,
      payload,
      store,
    }
    const returnRaw = Boolean(configReturnRaw || paramsMeta?.returnRaw)
    const conditional = Boolean(configConditional || paramsMeta?.conditional)
    const apiFetch = (uri, body, opts = {}) => {
      const options = { ...opts, headers: { ...opts?.headers } }
      if (conditional && options.method && options.method.match(/patch|put/i)) {
        let id = payload[idAttribute]
        if (!id && schema.idAttrbute !== idAttribute) {
          id = payload[schema.idAttrbute]
        }
        if (!id) {
          id = idOrValue(payload)
        }
        if (id) {
          const { [`${schema.key}_${id}`]: entity } = store.selectEntities()
          if (entity) {
            const {
              [lastModifiedAttribute]: lastModified = payload[lastModifiedAttribute],
            } = entity
            if (lastModified) {
              options.headers['If-Unmodified-Since'] = new Date(lastModified).toUTCString()
            } else {
              console.warn(
                `Missing ${lastModifiedAttribute} for ${schema.key}_${id}:`,
                { payload, entity }
              )
            }
          }
        } else {
          console.warn(`No ${idAttribute} found for ${schema.key} entity:`, payload)
        }
      }
      return apiFetchOriginal(uri, body, options)
    }

    dispatch({
      type: types.start,
      payload: dispatchPayload,
      meta: getMeta({ defaultMeta, status: 'start', paramsMeta }),
    })
    try {
      let response
      const request = handler({ ...handlerArgs, apiFetch, dispatch, payload, store })
      if (remove) {
        let canRemove = false
        try {
          response = await request
          canRemove = true
        } catch (error) {
          if ('status' in error && error.status === 404) {
            canRemove = true
          } else {
            throw error
          }
        } finally {
          if (canRemove) {
            dispatch({
              type: types.succeed,
              payload: dispatchPayload,
              meta: getMeta({ defaultMeta, status: 'succeed', paramsMeta }),
            })
            if (meta.snackbar !== false) {
              createSnackbarAction({
                ...sharedSnackbarArgs,
                status: 'succeed',
                response,
              })
            }
            const entities = store.selectEntities()
            const selfEntity = entities[name]?.[dispatchPayload]
            const toRemove = getRemovePayload(payload)
            if (cascade.length) {
              cascade.forEach(([child, parentKey]) => {
                if (child in entities) {
                  const { [child]: children } = entities
                  const remover = cascadeRemovers[child]
                  Object.assign(toRemove, remover(Object.values(children).filter(c => c[parentKey] === dispatchPayload)))
                }
              })
            }
            logger.debug('default remove handler removing', singularName, dispatchPayload, 'from entities', toRemove)
            dispatch(doEntitiesRemoved(toRemove, getMeta({
              defaultMeta,
              status: 'entities',
              paramsMeta
            })))
            if (parents) {
              logger.debug('removing', singularName, selfEntity, 'references from parents', parents)
              if (selfEntity) {
                let updating = false
                const parentEntities = Object.entries(parents).reduce((parentAcc, [parentEntity, parentIdAttribute]) => {
                  const parentId = selfEntity[parentIdAttribute]
                  const { [parentId]: oldParent } = entities[parentEntity] ?? {}
                  let updated = false
                  if (oldParent) {
                    const parent = { ...oldParent }
                    if (name in parent) {
                      updated = true
                      parent[name] = parent[name].filter(id => id != dispatchPayload)
                    } else if (singularName in parent) {
                      updated = true
                      parent[singularName] = null
                    }
                    if (updated) {
                      updating = true
                      parentAcc[parentEntity] = { [parentId]: parent }
                    }
                  }
                  return parentAcc
                }, {})
                if (updating) {
                  meta.replace = false
                  logger.debug('removing', singularName, selfEntity, 'from parents', parentEntities)
                  dispatch(doEntitiesReceived(parentEntities, meta))
                }
              }
            }
          }
        }
        return true
      }
      response = await request
      const { entities, result } = normalize(response, schema)
      const isNew = !payload.id
      const currentId = response[idAttribute] ?? result
      if (isNew) {
        logger.debug('initial entities to receive when adding new', singularName, result, entities)
        const newEntity = entities?.[name]?.[result]

        if (newEntity && parents) {
          const allEntities = store.selectEntities()
          meta.replace = false
          Object.entries(parents).forEach(([parentEntity, parentIdAttribute]) => {
            const parentEntities = allEntities[parentEntity]
            const parentId = newEntity?.[parentIdAttribute]
            if (!parentEntities || !parentId || !(parentId in parentEntities)) {
              logger.warn(
                `Missing parent with id ${parentId} in ${parentEntity} for ${name} entity ${result}:`,
                { newEntity, parentEntities, parentIdAttribute }
              )
              return
            }
            logger.debug('updating parent entity:', parentEntity, parentId, 'with', name, result, { newEntity })
            if (parentId in parentEntities) {
              const { [parentId]: oldParent } = parentEntities
              logger.debug('old parent:', oldParent)
              const parent = { ...oldParent }
              if (name in parent) {
                parent[name] = [...parent[name], newEntity?.id]
              } else if (singularName in parent) {
                parent[singularName] = newEntity?.id
              }
              entities[parentEntity] = { [parentId]: parent }
              logger.debug('updated parent:', parent)
              logger.debug('updated entities to receive:', entities)
            }
          }, EMPTY_OBJECT)
        }
      }
      dispatch({
        type: ENTITIES_RECEIVED,
        payload: entities,
        meta,
      })
      dispatch({
        type: types.succeed,
        payload: currentId,
        meta: getMeta({
          isNew,
          cid: isNew && dispatchPayload,
          defaultMeta,
          status: 'succeed',
          paramsMeta,
        }),
      })

      if (meta.snackbar !== false) {
        createSnackbarAction({
          ...sharedSnackbarArgs,
          status: 'succeed',
          response,
        })
      }
      return returnRaw || Array.isArray(result)
        ? response
        : entities[name][result]
    } catch (error) {
      dispatch({
        type: types.fail,
        payload: dispatchPayload,
        error,
        meta: getMeta({
          defaultMeta,
          status: 'fail',
          paramsMeta,
          requestPayload: payload,
        }),
      })
      if (meta.snackbar !== false) {
        createSnackbarAction({
          ...sharedSnackbarArgs,
          status: 'fail',
          error,
        })
      }
      if (localStorage.throwApiErrors === 'true') throw error
      return false
    }
  }

  return Object.assign(creator, identifiers, { createSnackbarAction, handler })
}

export const actionFactory = (...args) => {
  const identifiers = getActionIdentifiers(...args)
  const { type } = identifiers
  const creator = (payload, meta) => ({ type, payload, meta })
  return Object.assign(creator, identifiers)
}

/**
 * Shape of createCustomAction output
 * @typedef CustomAction
 * @property {import("./constants").AsyncActionIdentifiers} actionIdentifiers
 * @property {import("redux").Reducer} actionReducer
 * @property {Object} defaultState
 */
/**
 *
 * @param {Object} params
 * @param {string} params.actionName
 * @param {string} params.actionType
 * @param {any} [params.defaultData=[]]
 * @param {string} [params.loadingKey='loading']
 * @returns {CustomAction}
 */
export const createCustomAction = ({
  actionName,
  actionType,
  defaultData = [],
  loadingKey = 'loading',
  reducerKey
}) => {
  const actionIdentifiers = getAsyncActionIdentifiers(actionType, actionName)
  const actionReducer = (state, action) => {
    switch (action.type) {
      case actionIdentifiers.types.start:
        return {
          ...state,
          [reducerKey]: {
            ...state[reducerKey],
            ...action?.payload,
            [loadingKey]: true,
          },
        }
      case actionIdentifiers.types.succeed:
        return {
          ...state,
          [reducerKey]: {
            ...state[reducerKey],
            [loadingKey]: false,
            data: action.payload,
          },
        }
      case actionIdentifiers.types.fail:
        return {
          ...state,
          [reducerKey]: {
            ...state[reducerKey],
            [loadingKey]: false,
            error: action.error,
          },
        }
      default:
        return state
    }
  }
  const defaultState = { [reducerKey]: { [loadingKey]: false, data: defaultData } }
  return { actionIdentifiers, actionReducer, defaultState }
}
