import { createSelector } from 'redux-bundler'

import { normalize } from 'normalizr'

import createEntityBundle, { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import { parseApiErrors } from '~/src/Lib/Utils'
import { IrrigationSchedule as schema } from '~/src/Store/Schemas'

const name = 'irrigationSchedules'

const createIrrigationScheduleSnackbar = ({
  dispatch,
  status,
  message,
}) => dispatch({
  actionCreator: 'doAddSnackbarMessage',
  args: [message, status],
})

const entityBundle = createEntityBundle({
  name,
  apiConfig: {
    schema,
    prepareData: ({
      sensorBasedIrrigationCooldownMinutes,
      sensorBasedIrrigationMinutes,
      ...rest
    }) => ({
      ...rest,
      sensorBasedIrrigationCooldownSeconds: sensorBasedIrrigationCooldownMinutes ? sensorBasedIrrigationCooldownMinutes * 60 : sensorBasedIrrigationCooldownMinutes,
      sensorBasedIrrigationSeconds: sensorBasedIrrigationMinutes ? sensorBasedIrrigationMinutes * 60 : sensorBasedIrrigationMinutes,
    })
  },
  scope: {
    selector: 'selectIrrigationScheduleScopeContext',
    test: ({ currentFacility, recipes }, schedule) => (
      !schedule.facility
      || schedule.facility === currentFacility.id
      || (schedule.harvest in recipes && schedule.organization === currentFacility.organization)
    )
  },
})

export default {
  ...entityBundle,
  selectIrrigationScheduleScopeContext: createSelector(
    'selectCurrentFacility',
    'selectRecipes',
    (currentFacility, recipes) => ({ currentFacility, recipes }),
  ),
  doPublishIrrigationSchedule: payload => async ({ dispatch, apiFetch }) => {
    const { id, action } = payload
    const publishingMessage = {
      phase: 'Started publishing an irrigation schedule',
      new: 'Started publishing a new irrigation schedule',
      edit: 'Publishing edited irrigation schedule',
      delete: 'Publishing deletion of irrigation schedule',
    }
    let result
    try {
      result = await apiFetch(`/irrigationSchedules/${id}/publish/`, null, { method: 'POST' })
      createIrrigationScheduleSnackbar({
        dispatch,
        status: 'success',
        message: publishingMessage[action],
      })
      if (result.active !== false) {
        const { entities } = normalize(result, schema)
        dispatch(doEntitiesReceived(entities))
      }
    } catch (error) {
      createIrrigationScheduleSnackbar({
        dispatch,
        status: 'error',
        message: `Unable to publish irrigation schedule: ${parseApiErrors(error)}`,
      })
    }
    return result
  },
  doFetchIrrigationScheduleStatus: data => async ({ apiFetch, dispatch }) => {
    const { id } = data

    let result
    try {
      const scheduleStatus = await apiFetch(`/irrigationSchedules/${id}/publish/`, null, { method: 'GET' })
      const entities = { irrigationSchedules: { [id]: { status: scheduleStatus } } }
      result = { ...data, status: scheduleStatus }
      dispatch(doEntitiesReceived(entities, { replace: false }))
    } catch (err) {
      const errorMessage = parseApiErrors(err) || 'Failed to fetch schedule status'
      createIrrigationScheduleSnackbar({ dispatch, message: errorMessage, status: 'error' })
    }
    return result
  },
  selectIrrigationSchedulesByPhase: createSelector(
    'selectIrrigationSchedules',
    'selectRecipes',
    (irrigationSchedules, recipes) => ({
      ...Object.fromEntries(Object.values(irrigationSchedules).map(
        irrigationSchedule => (irrigationSchedule?.phase ? [irrigationSchedule.phase, irrigationSchedule] : null)
      ).filter(Boolean)),
      ...Object.fromEntries(Object.values(recipes).reduce((res, recipe) => [...res, ...recipe.phases], [])
        .map(phase => (phase?.irrigationSchedule ? [phase?.id, phase?.irrigationSchedule] : null)).filter(Boolean))
    })
  ),
}
